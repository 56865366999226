import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ElementImage from '@components/global/element/Image'

import * as cx from './IdxCoverageFlags.module.scss'

const IDXCoverageFlags = ({ items }) => {
  return (
    <section id="idx-coverage-flags" className={clsx(cx.wrapper)}>
      <h2 className="heading-6">International</h2>
      <ul>
        {items.map((item, index) => {
          return (
            <li key={index}>
              <ElementImage
                src={item.flag}
                width={75}
                height={70}
                alt={item.name}
              />
              <span className="small-heading strong">{item.name}</span>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

IDXCoverageFlags.propTypes = {
  items: PropTypes.array,
}

export default IDXCoverageFlags
