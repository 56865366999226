import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { getImage } from 'gatsby-plugin-image'
import { useIdxCoverageMetadata } from '@hooks/idx-coverage'
import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './IdxAds.module.scss'
import { removeElementTags } from '@utils/removeElementTags'
import { extractGatsbyImageDataRelativePath } from '@utils/imageFile'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import useMedia, { media } from '@hooks/useMedia'

const IDXAds = ({
  items = [
    {
      background: useIdxCoverageMetadata().AdsPackagesBackground,
      image: useIdxCoverageMetadata().AdsPackages,
      title: 'IDX <br />Packages',
      subtitle: null,
      description: 'Flexible Solutions For <br />Agents, Brokers and Teams',
      button: {
        label: 'Learn More',
        url: '/',
      },
    },
    {
      background: useIdxCoverageMetadata().AdsAreasBackground,
      image: useIdxCoverageMetadata().AdsAreas,
      title: 'IDX <br />Areas Covered',
      subtitle: null,
      description:
        'If you’d like to see what MLS, iHomefinder and <br />IDX Broker areas are covered, follow this link.',
      button: {
        label: 'Learn More',
        url: '/',
      },
    },
    {
      background: useIdxCoverageMetadata().AdsConsultationBackground,
      image: useIdxCoverageMetadata().AdsConsultation,
      title: 'IDX Consultation',
      subtitle: 'Not sure where to begin with <br />your IDX Package?',
      description:
        'If you’d like to see what MLS, iHomefinder and <br />IDX Broker areas are covered, follow this link.',
      button: {
        label: 'Learn More',
        url: '/',
      },
    },
  ],
  skip = 'IDX Areas Covered',
}) => {
  const isDeviceMinTablet = useMedia(media.minTablet)
  const [isMinTablet, setIsMinTablet] = useState(false)

  useEffect(() => {
    setIsMinTablet(isDeviceMinTablet)
  }, [isDeviceMinTablet])

  return (
    <section id="idx-coverage-ads" className={clsx(cx.wrapper)}>
      {items.map((item, index) => {
        if (skip !== removeElementTags(item.title)) {
          return (
            <div key={index} className={clsx(cx.item)}>
              <div
                className={clsx(cx.background)}
                style={{
                  backgroundImage: `url(${extractGatsbyImageDataRelativePath(
                    item.background
                  )})`,
                }}
              ></div>

              <div className={clsx(cx.content)}>
                {item.title && (
                  <h2 className="heading-2">{parse(item.title)}</h2>
                )}
                {item.subtitle && (
                  <>
                    <p
                      className={clsx(
                        isMinTablet && 'subtitle-9',
                        !isMinTablet && 'subtitle-6'
                      )}
                    >
                      {parse(item.subtitle)}
                    </p>
                    <p className="subtitle-5">{parse(item.description)}</p>
                  </>
                )}
                {!item.subtitle && item.description && (
                  <p className="default-body large-all">
                    {parse(item.description)}
                  </p>
                )}
                <Link to={item.button.url} className="primary-button large">
                  {item.button.label}
                </Link>
              </div>
              <ElementImageFile
                src={getImage(item.image)}
                alt={removeElementTags(item.title)}
                lazy={true}
              />
            </div>
          )
        }
      })}
    </section>
  )
}

IDXAds.propTypes = {
  items: PropTypes.array,
  skip: PropTypes.string,
}

export default IDXAds
