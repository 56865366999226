import React from 'react'
import { Link, graphql } from 'gatsby'
import parse from 'html-react-parser'
import clsx from 'clsx'
import { useIdxCoverageMetadata, pageData } from '@hooks/idx-coverage'

// Components
import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import IDXCoverageBanner from '@components/pages/resources/idx-coverage/banner'
import IDXCoverageSearch from '@components/pages/resources/idx-coverage/search'
import IDXCoverageUsa from '@components/pages/resources/idx-coverage/usa'
import IDXCoverageCanada from '@components/pages/resources/idx-coverage/canada'
import IDXCoverageFlags from '@components/pages/resources/idx-coverage/flags'
import IDXCoverageNationwide from '@components/pages/resources/idx-coverage/nationwide'
import IDXAds from '@components/pages/resources/idx-ads'

import * as cx from './IdxCoverage.module.scss'

const IDXCoverage = ({ data: { page }, pageContext: { seo, uri } }) => {
  const pageMetaData = useIdxCoverageMetadata()

  return (
    <LayoutInnerPage>
      <Seo title={page.title} seo={seo} uri={uri} />
      <IDXCoverageBanner
        breadcrumbs={page?.seo?.breadcrumbs}
        image={pageMetaData?.Hero}
        imageMobile={pageMetaData?.MobileHero}
      />
      <IDXCoverageSearch />
      <IDXCoverageUsa />
      <IDXCoverageCanada />
      <IDXCoverageFlags items={pageData.flags} />
      <IDXCoverageNationwide items={pageData.nationwide} />
      <IDXAds />
    </LayoutInnerPage>
  )
}

export default IDXCoverage

export const IDXCoveragePageQuery = graphql`
  query IDXCoveragePageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }
    }
  }
`
