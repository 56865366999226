import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ElementImage from '@components/global/element/Image'
import MlsAccordion from '@components/global/accordions/MlsAccordion'

import * as cx from './IdxCoverageNationwide.module.scss'

import SvgArrowAlt from '@svg/arrowAlt.inline'

const IDXCoverageNationwide = ({
  background = 'https://resources.agentimage.com/redesign/local/idx-coverage/nationwide-bg.jpg',
  items,
}) => {
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState('960px')

  const contentSpace = useRef(null)

  const toggleAccordion = (e) => {
    setActive(!active)
    setHeight(active ? '960px' : `${contentSpace.current.scrollHeight}px`)
  }

  return (
    <section id="idx-coverage-nationwide-lists" className={clsx(cx.wrapper)}>
      <div className={clsx(cx.heading)}>
        <div
          className={clsx(cx.background)}
          style={{ backgroundImage: `url(${background})` }}
        ></div>
        <p className="subtitle-7">Nationwide IDX Coverage</p>
        <h2 className="heading-5">Full List of Areas Covered</h2>
      </div>

      <div className={clsx(cx.content)}>
        <div className={clsx(cx.divider)}></div>
        <div className={clsx(cx.trow)}>
          <div className={clsx(cx.tcol)}>
            <span className="montserrat-body uppercase">
              MLS Boards by Area
            </span>
          </div>
          <div className={clsx(cx.tcol, cx.status)}>
            <div className={clsx(cx.cont)}>
              <ElementImage
                src="https://resources.agentimage.com/redesign/local/idx-coverage/ihf-logo.png"
                width={25}
                height={29}
                alt="iHomefinder"
              />
              <span className="small-heading strong">iHomefinder</span>
              <div className={clsx(cx.divider)}></div>
            </div>

            <div className={clsx(cx.cont)}>
              <ElementImage
                src="https://resources.agentimage.com/redesign/local/idx-coverage/idx-logo.png"
                width={59}
                height={21}
                alt="IDX Broker"
              />
              <span className="small-heading strong">IDX Broker</span>
              <div className={clsx(cx.divider)}></div>
            </div>
          </div>
        </div>

        <div
          ref={contentSpace}
          className={clsx(cx.items, active && cx.activeItems)}
          style={{ maxHeight: height }}
        >
          {items.map((item, index) => {
            return (
              <MlsAccordion
                key={index}
                btnClass={clsx('heading-6')}
                title={item.name}
                abbr={item.abbr}
                item={item}
              />
            )
          })}

          {!active && (
            <button
              className={clsx(cx.btn, 'primary-button alt svg')}
              type="button"
              onClick={toggleAccordion}
            >
              View More Areas <SvgArrowAlt />
            </button>
          )}

          <button
            className={clsx(cx.btnLess, 'primary-button large alt svg')}
            type="button"
            onClick={toggleAccordion}
          >
            View Less Areas <SvgArrowAlt />
          </button>
        </div>
      </div>
    </section>
  )
}

IDXCoverageNationwide.propTypes = {
  items: PropTypes.array,
}

export default IDXCoverageNationwide
