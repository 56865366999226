import { useStaticQuery, graphql } from 'gatsby'

export const useIdxCoverageMetadata = () => {
  const query = useStaticQuery(graphql`
    query IdxCoverageMetaData {
      Hero: file(relativePath: { eq: "idx-coverage/hero.png" }) {
        id
        ...IdxCoverageImageQuality
      }
      MobileHero: file(relativePath: { eq: "idx-coverage/mobile-hero.png" }) {
        id
        ...IdxCoverageImageQuality
      }
      AdsPackagesBackground: file(
        relativePath: { eq: "idx-coverage/idx-packages/background.jpg" }
      ) {
        id
        ...IdxCoverageImageQuality
      }
      AdsPackages: file(
        relativePath: { eq: "idx-coverage/idx-packages/featured.png" }
      ) {
        id
        ...IdxCoverageImageQuality
      }
      AdsConsultationBackground: file(
        relativePath: { eq: "idx-coverage/idx-consultation/background.jpg" }
      ) {
        id
        ...IdxCoverageImageQuality
      }
      AdsConsultation: file(
        relativePath: { eq: "idx-coverage/idx-consultation/featured.png" }
      ) {
        id
        ...IdxCoverageImageQuality
      }
      AdsAreasBackground: file(
        relativePath: { eq: "idx-coverage/idx-areas/background.jpg" }
      ) {
        id
        ...IdxCoverageImageQuality
      }
      AdsAreas: file(
        relativePath: { eq: "idx-coverage/idx-areas/featured.png" }
      ) {
        id
        ...IdxCoverageImageQuality
      }
    }

    fragment IdxCoverageImageQuality on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export const pageData = {
  flags: [
    {
      flag:
        'https://resources.agentimage.com/redesign/local/idx-coverage/bahamas.png',
      name: 'Bahamas',
    },
    {
      flag:
        'https://resources.agentimage.com/redesign/local/idx-coverage/baja.png',
      name: 'Baja California Sur',
    },
    {
      flag:
        'https://resources.agentimage.com/redesign/local/idx-coverage/jamaica.png',
      name: 'Jamaica',
    },
    {
      flag:
        'https://resources.agentimage.com/redesign/local/idx-coverage/mexico.png',
      name: 'Mexico',
    },
    {
      flag:
        'https://resources.agentimage.com/redesign/local/idx-coverage/panama.png',
      name: 'Panama',
    },
    {
      flag:
        'https://resources.agentimage.com/redesign/local/idx-coverage/puerto-rico.png',
      name: 'Puerto Rico',
    },
  ],
  nationwide: [
    {
      name: 'ALABAMA',
      abbr: 'AL',
      providers: [
        {
          name: 'Antelope Valley MLS (ANTV)',
          ihf: false,
          idx: true,
        },
        {
          name:
            'Central Coast (CRMLS Boards — Paso Robles, Atascadero, San Luis Obispo, Morro Bay)',
          ihf: false,
          idx: true,
        },
      ],
    },
    {
      name: 'ALASKA',
      abbr: 'AK',
      providers: [
        {
          name: 'Antelope Valley MLS (ANTV)',
          ihf: true,
          idx: false,
        },
        {
          name:
            'Contra Costa Association of REALTORS® (BayEast-CCAR-EBRD/Bridge IDX listings only)',
          ihf: true,
          idx: false,
        },
      ],
    },
    {
      name: 'ARIZONA',
      abbr: 'AZ',
      providers: [
        {
          name: 'Antelope Valley MLS (ANTV)',
          ihf: false,
          idx: true,
        },
        {
          name:
            'Central Coast (CRMLS Boards — Paso Robles, Atascadero, San Luis Obispo, Morro Bay)',
          ihf: false,
          idx: true,
        },
      ],
    },
    {
      name: 'ARKANSAS',
      abbr: 'AR',
      providers: [
        {
          name: 'Antelope Valley MLS (ANTV)',
          ihf: true,
          idx: true,
        },
      ],
    },
    {
      name: 'CALIFORNIA',
      abbr: 'CA',
      providers: [
        {
          name: 'Antelope Valley MLS (ANTV)',
          ihf: true,
          idx: true,
        },
        {
          name:
            'Central Coast (CRMLS Boards — Paso Robles, Atascadero, San Luis Obispo, Morro Bay)',
          ihf: true,
          idx: true,
        },
        {
          name:
            'Contra Costa Association of REALTORS® (BayEast-CCAR-EBRD/Bridge IDX listings only)',
          ihf: true,
          idx: false,
        },
      ],
    },
    {
      name: 'COLORADO',
      abbr: 'CO',
      providers: [],
    },
    {
      name: 'CONNECTICUT',
      abbr: 'CT',
      providers: [],
    },
    {
      name: 'DELAWARE',
      abbr: 'DE',
      providers: [],
    },
    {
      name: 'FLORIDA',
      abbr: 'FL',
      providers: [],
    },
    {
      name: 'GEORGIA',
      abbr: 'GA',
      providers: [],
    },
    {
      name: 'HAWAII',
      abbr: 'HI',
      providers: [],
    },
    {
      name: 'IDAHO',
      abbr: 'ID',
      providers: [],
    },
    {
      name: 'ILLINOIS',
      abbr: 'IL',
      providers: [],
    },
    {
      name: 'INDIANA',
      abbr: 'IN',
      providers: [],
    },
    {
      name: 'IOWA',
      abbr: 'IA',
      providers: [],
    },
    {
      name: 'KANSAS',
      abbr: 'KS',
      providers: [],
    },
    {
      name: 'KENTUCKY',
      abbr: 'KY',
      providers: [],
    },
    {
      name: 'LOUISIANA',
      abbr: 'LA',
      providers: [],
    },
    {
      name: 'MAINE',
      abbr: 'ME',
      providers: [],
    },
    {
      name: 'MARYLAND',
      abbr: 'MD',
      providers: [],
    },
    {
      name: 'MASSACHUSETTS',
      abbr: 'MA',
      providers: [],
    },
    {
      name: 'MICHIGAN',
      abbr: 'MI',
      providers: [],
    },
    {
      name: 'MINNESOTA',
      abbr: 'MN',
      providers: [],
    },
    {
      name: 'MISSISSIPPI',
      abbr: 'MS',
      providers: [],
    },
    {
      name: 'MISSOURI',
      abbr: 'MO',
      providers: [],
    },
    {
      name: 'MONTANA',
      abbr: 'MT',
      providers: [],
    },
    {
      name: 'NEBRASKA',
      abbr: 'NE',
      providers: [],
    },
    {
      name: 'NEVADA',
      abbr: 'NV',
      providers: [],
    },
    {
      name: 'NEW HAMPSHIRE',
      abbr: 'NH',
      providers: [],
    },
    {
      name: 'NEW JERSEY',
      abbr: 'NJ',
      providers: [],
    },
    {
      name: 'NEW MEXICO',
      abbr: 'NM',
      providers: [],
    },
    {
      name: 'NEW YORK',
      abbr: 'NY',
      providers: [],
    },
    {
      name: 'NORTH CAROLINA',
      abbr: 'NC',
      providers: [],
    },
    {
      name: 'NORTH DAKOTA',
      abbr: 'ND',
      providers: [],
    },
    {
      name: 'OHIO',
      abbr: 'OH',
      providers: [],
    },
    {
      name: 'OKLAHOMA',
      abbr: 'OK',
      providers: [],
    },
    {
      name: 'OREGON',
      abbr: 'OR',
      providers: [],
    },
    {
      name: 'PENNSYLVANIA',
      abbr: 'PA',
      providers: [],
    },
    {
      name: 'RHODE ISLAND',
      abbr: 'RI',
      providers: [],
    },
    {
      name: 'SOUTH CAROLINA',
      abbr: 'SC',
      providers: [],
    },
    {
      name: 'SOUTH DAKOTA',
      abbr: 'SD',
      providers: [],
    },
    {
      name: 'TENNESSEE',
      abbr: 'TN',
      providers: [],
    },
    {
      name: 'TEXAS',
      abbr: 'TX',
      providers: [],
    },
    {
      name: 'UTAH',
      abbr: 'UT',
      providers: [],
    },
    {
      name: 'VERMONT',
      abbr: 'VT',
      providers: [],
    },
    {
      name: 'VIRGIN ISLANDS',
      abbr: 'VI',
      providers: [],
    },
    {
      name: 'VIRGINIA',
      abbr: 'VA',
      providers: [],
    },
    {
      name: 'WASHINGTON',
      abbr: 'WA',
      providers: [],
    },
    {
      name: 'WASHINGTON DC',
      abbr: 'DC',
      providers: [],
    },
    {
      name: 'WEST VIRGINIA',
      abbr: 'WV',
      providers: [],
    },
    {
      name: 'WISCONSIN',
      abbr: 'WI',
      providers: [],
    },
    {
      name: 'WYOMING',
      abbr: 'WY',
      providers: [],
    },
    {
      name: 'Canada',
      abbr: '',
      providers: [],
    },
    {
      name: 'International',
      abbr: '',
      providers: [],
    },
  ],
}
