import { useEffect, useState } from 'react'

const useAutocomplete = (items, inputSearchRef) => {
  const [searchedValue, setSearchedValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [selectedSuggestion, setSelectedSuggestion] = useState('')
  const [activeSuggestion, setActiveSuggestion] = useState(0)

  useEffect(() => {
    if (inputSearchRef) {
      inputSearchRef.focus()
    }
  }, [])

  const handleChange = (event) => {
    if (event.target.value !== '') {
      const filteredSuggestions = items.filter((itemData) => {
        const value = event.target.value.toUpperCase()
        const name = itemData.name.toUpperCase()

        return value && name.startsWith(value) && name !== value
      })
      setSearchedValue(event.target.value)
      setSuggestions(filteredSuggestions)
    } else {
      setSearchedValue(event.target.value)
      setSuggestions([])
      setActiveSuggestion(0)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown' && activeSuggestion < suggestions.length) {
      setActiveSuggestion(activeSuggestion + 1)
    } else if (event.key === 'ArrowUp' && activeSuggestion > 1) {
      setActiveSuggestion(activeSuggestion - 1)
    } else if (event.key === 'Enter') {
      setSearchedValue(suggestions[activeSuggestion - 1].name)
      setSelectedSuggestion(suggestions[activeSuggestion - 1].name)
      setSuggestions([])
      setActiveSuggestion(0)
    }
  }

  const handleClick = (value) => {
    setSelectedSuggestion(value)
    setSearchedValue(value)
    setSuggestions([])
    setActiveSuggestion(0)
  }

  return {
    searchedValue,
    suggestions,
    selectedSuggestion,
    activeSuggestion,
    handleChange,
    handleKeyDown,
    handleClick,
  }
}

export default useAutocomplete
