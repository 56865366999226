import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'
import { modifyImageKeys } from '@utils/modifyImageKeys'

// Element
import ElementImageFile from '@components/global/element/ImageFile'

import Breadcrumbs from '@components/seo/Breadcrumbs'

import * as cx from './IdxCoverageBanner.module.scss'

const IDXCoverageBanner = ({
  breadcrumbs,
  backgroundUrl = 'https://resources.agentimage.com/redesign/local/idx-coverage/background.jpg',
  title = 'Agent Image Blog',
  description = 'Learn the tips and tools you need to succeed at real estate marketing in the digital age.',
  image,
  imageMobile,
}) => {
  return (
    <section
      id="idx-coverage-banner"
      className={clsx(cx.wrapper)}
      style={{
        backgroundImage: `url(${backgroundUrl})`,
      }}
    >
      <div className="container">
        <Breadcrumbs
          wrapperClassName={clsx(cx.breadcrumbs)}
          data={breadcrumbs}
        />

        <div className={clsx(cx.heading)}>
          <h1 className="heading-2">IDX Coverage</h1>
          <p className="subtitle-6">List of MLS Boards and Coverage</p>
        </div>

        {image && (
          <ElementImageFile
            className={clsx(cx.hero, imageMobile && 'desktop-tablet-only')}
            src={getImage(image)}
            alt="IDX Coverage"
            lazy={true}
          />
        )}

        {imageMobile && (
          <ElementImageFile
            className={clsx(cx.hero, cx.mobileHero, 'mobile-no-tablet-only')}
            src={getImage(imageMobile)}
            alt="IDX Coverage"
            lazy={true}
          />
        )}
      </div>
    </section>
  )
}

IDXCoverageBanner.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  backgroundUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  imageMobile: PropTypes.object,
}

export default IDXCoverageBanner
