// extracted by mini-css-extract-plugin
export var activeItems = "IdxCoverageNationwide-module--active-items--cfb9c";
export var background = "IdxCoverageNationwide-module--background--7f939";
export var btn = "IdxCoverageNationwide-module--btn--0a0bf";
export var btnLess = "IdxCoverageNationwide-module--btn-less--6a3c7";
export var cont = "IdxCoverageNationwide-module--cont--9e490";
export var content = "IdxCoverageNationwide-module--content--58f19";
export var divider = "IdxCoverageNationwide-module--divider--37c60";
export var heading = "IdxCoverageNationwide-module--heading--53434";
export var items = "IdxCoverageNationwide-module--items--672d9";
export var status = "IdxCoverageNationwide-module--status--4246a";
export var tcol = "IdxCoverageNationwide-module--tcol--0146d";
export var trow = "IdxCoverageNationwide-module--trow--a9d7f";
export var wrapper = "IdxCoverageNationwide-module--wrapper--c3924";