import React, { useState, useRef, forwardRef } from 'react'
import clsx from 'clsx'
import parse from 'html-react-parser'

import * as cx from './MlsAccordion.module.scss'

import SvgArrowAlt from '@svg/arrowAlt.inline'
import SvgCheckedCircle from '@svg/checkedCircle.inline'

const MlsAccordion = forwardRef(
  (
    { parentClass = '', btnClass = 'small-heading', childClass = '', item },
    ref
  ) => {
    const [active, setActive] = useState(false)
    const [height, setHeight] = useState('0px')

    const contentSpace = useRef(null)

    const toggleAccordion = (e) => {
      setActive(!active)
      setHeight(active ? '0px' : `${contentSpace.current.scrollHeight}px`)
    }

    return (
      <div
        className={clsx(
          cx.parent,
          parentClass,
          'accordion-parent',
          active && `accordion-parent-active`
        )}
      >
        <button
          type="button"
          className={clsx(
            btnClass,
            'accordion-button',
            active && `accordion-active ${cx.active}`,
            item.providers.length === 0 && cx.inactive
          )}
          onClick={toggleAccordion}
        >
          <span className={clsx(cx.svg)}>
            <SvgArrowAlt />
          </span>{' '}
          {item.name} {item.abbr && parse(`<span>(${item.abbr})</span>`)}
        </button>
        <div
          ref={contentSpace}
          style={{ maxHeight: height }}
          className={clsx(cx.child, 'accordion-child')}
        >
          <div className={clsx(childClass)}>
            {item.providers.map((provider, index) => {
              return (
                <div key={index} className={clsx(cx.subitem)}>
                  <div className={clsx(cx.itemContent)}>
                    <div className="default-body large">{provider.name}</div>
                    <div className={clsx(cx.checklist)}>
                      <div className={clsx(cx.check)}>
                        {provider.ihf && (
                          <>
                            <SvgCheckedCircle />
                            <div className="default-body large">
                              iHomefinder
                            </div>
                          </>
                        )}
                      </div>
                      <div className={clsx(cx.check)}>
                        {provider.idx && (
                          <>
                            <SvgCheckedCircle />
                            <div className="default-body large">IDX Broker</div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
)

export default MlsAccordion
