import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import useAutocomplete from '@hooks/useAutocomplete'
import ElementImage from '@components/global/element/Image'

import * as cx from './IdxCoverageSearch.module.scss'

const IDXCoverageSearch = ({
  backgroundUrl = 'https://resources.agentimage.com/redesign/local/idx-coverage/search-background.jpg',
  items = [
    {
      name: 'California Desert Association of REALTOR...',
    },
    {
      name: 'California Real Estate Technology Service...',
    },
    {
      name: 'California Regional MLS (CRMLS)',
    },
    {
      name: 'Florida',
    },
    {
      name: 'New York',
    },
    {
      name: 'Los Angeles',
    },
  ],
}) => {
  const inputSearchRef = useRef()

  useEffect(() => {
    if (inputSearchRef.current) {
      inputSearchRef.current.focus()
    }
  }, [])

  const {
    searchedValue,
    suggestions,
    selectedSuggestion,
    activeSuggestion,
    handleChange,
    handleKeyDown,
    handleClick,
  } = useAutocomplete(items, inputSearchRef.current)

  return (
    <section
      id="idx-coverage-search"
      className={clsx(cx.wrapper)}
      style={{ backgroundImage: `url(${backgroundUrl})` }}
    >
      <div className={clsx(cx.content)}>
        <h2 className="heading-5">Search Your MLS Board</h2>
        <p className="subtitle-5">
          Use the maps or list of locations below to locate details for MLS®
          Systems in your area. <br />
          If you don’t know the name of the MLS® System used by your board or
          association, try our search bar.
        </p>

        <form>
          <div className="form-row">
            <div className="form-col form-col-input form-control">
              <input
                ref={inputSearchRef}
                placeholder="Enter MLS Board"
                type="text"
                value={searchedValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />

              {suggestions.length > 0 && (
                <ul className={clsx(cx.lists)}>
                  {suggestions.map((item, index) => {
                    if (index < 3) {
                      return (
                        <li
                          key={index}
                          // className={clsx(
                          //   cx.activeItem && index === activeSuggestion - 1
                          // )}
                          onClick={() => handleClick(item.name)}
                        >
                          <span>{item.name}</span>
                        </li>
                      )
                    }
                  })}
                </ul>
              )}
            </div>
            <div className="form-col">
              <button className="primary-button xlarge">Search</button>
            </div>
          </div>
        </form>

        <div className={clsx(cx.divider)}></div>

        <div className={clsx(cx.providers)}>
          <div className={clsx(cx.provider)}>
            <ElementImage
              src="https://resources.agentimage.com/redesign/local/idx-coverage/ihf-logo.png"
              width={25}
              height={29}
              alt="iHomefinder"
            />

            <div className={clsx(cx.details)}>
              <h3 className="small-heading strong">ihomefinder</h3>
              <p className="small-heading">
                Coverage for most boards in the us and canada
              </p>
            </div>
          </div>
          <div className={clsx(cx.provider)}>
            <ElementImage
              src="https://resources.agentimage.com/redesign/local/idx-coverage/idx-logo.png"
              width={59}
              height={21}
              alt="IDX Broker"
            />

            <div className={clsx(cx.details)}>
              <h3 className="small-heading strong">IDX Broker</h3>
              <p className="small-heading">
                canada & international mls are only covered by idx broker
                platinum
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

IDXCoverageSearch.propTypes = {
  backgroundUrl: PropTypes.string,
  items: PropTypes.array,
}

export default IDXCoverageSearch
